import axios from "axios";
import "./Dashbord.css";
import { useEffect, useState } from "react";
import Navbar from "../../Comonent/Navbar/Navbar";

export default function EmailDashbord(prop) {
  const [Data, setData] = useState([]);
  const [rerunuseeffect, setrerunuseeffect] = useState(0);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("https://muthanna-alazawi.iq/api/Messages", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    };

    // Fetch data initially
    fetchData();

    // Set interval to fetch data every 5 minutes (300,000 ms)
    const intervalId = setInterval(fetchData, 30000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [rerunuseeffect]);

  console.log(Data);
  const token = localStorage.getItem("token");
  async function handDelete(id) {
    try {
      const res = await axios.delete(
        `https://muthanna-alazawi.iq/api/Messages/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setrerunuseeffect((prv) => prv + 1);
      }
    } catch (err) {
      console.log(err);
    }
  }
  console.log(Data);
  const showuser = Data.map((user, index) => (
    <>
      <tr key={index}>
        <td>{user.email}</td>
        <td>{user.msgContent}</td>
        <td>
          <img
            src="/Images/Delete.png"
            alt="Delete"
            className="Delete-button"
            style={{ width: "30px" }}
            data-bs-toggle="modal"
            data-bs-target={`#staticBackdrop-${user.id}`}
          />
        </td>
      </tr>

      <div
        className="modal fade"
        id={`staticBackdrop-${user.id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby={`staticBackdropLabel-${user.id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`staticBackdropLabel-${user.id}`}>
                تأكيد الحذف
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ margin: "0" }}
              ></button>
            </div>
            <div className="modal-body">هل حقا تريد حذف هذا الرسالة</div>
            <div
              className="modal-footer"
              dir="ltr"
              style={{
                flexDirection: "row-reverse",
                justifyContent: "flex-start",
              }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                الغاء
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => handDelete(user.id)}
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ));

  return (
    <>
      <Navbar show="Dasjbord" />
      <div className="size">
        <div className="container" dir="rtl">
          <h3 className="title-section">الرسائل </h3>
          <div className="parent-Table">
            <table className="table ">
              <thead>
                <tr>
                  <th> بريد المرسل </th>
                  <th> الرسالة </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="text-center align-middle desctop-table">
                {showuser}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
