import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { useEffect } from "react";
export default function Navbar(prop) {
  const nav = useNavigate();
  function handlogout() {
    localStorage.removeItem("token");
    nav("/Login");
  }

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".headtop");
      const parent_nav = document.querySelector(".parent-nav");
      if (window.scrollY > 0) {
        navbar.classList.add("scrolled");
        parent_nav.classList.add("reamve-margin");
      } else {
        navbar.classList.remove("scrolled");
        parent_nav.classList.remove("reamve-margin");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="headtop">
      {prop.show === "Home" ? (
        <div className="parent-nav navbar-expand-xxl">
          <div className="Icons">
            <a
              href="https://www.facebook.com/Muthannaalazaw6/?locale=ar_AR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="Images/fasbook.svg" alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/muthannaalazaw6/?igsh=MjlreTI1emhkdTh1&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="Images/instagram.svg" alt="Instagram" />
            </a>
            <a
              href="https://x.com/MuthannaAlazaw6?s=09"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="Images/X.svg" alt="Twitter" />
            </a>
            <a
              href="https://www.youtube.com/@muthannaalazaw6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="Images/youtop.svg" alt="YouTube" />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="nav-component collapse navbar-collapse"
            style={{ flexGrow: 0 }}
            id="navbarSupportedContent"
          >
            <ul className="list navbar-nav" dir="rtl">
              <a
                to="/"
                className="link-nav"
                activeclassname="active"
                href="#Home"
              >
                الرئيسية
              </a>
              <a className="link-nav" activeclassname="active" href="#cv">
                السيرة الذاتية
              </a>
              <a className="link-nav" activeclassname="active" href="#news">
                المنجزات
              </a>
              <a className="link-nav" activeclassname="active" href="#news">
                الاخبار
              </a>
              <a className="link-nav" activeclassname="active" href="#call-us">
                تواصل معنا
              </a>
            </ul>
          </div>
        </div>
      ) : (
        <div className="parent-nav navbar-expand-xxl">
          <div className="Icons" style={{ display: "block" }}>
            <div
              className="logout"
              onClick={handlogout}
              style={{ cursor: "pointer", marginLeft: "0" }}
            >
              تسجيل الخروج
            </div>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="nav-component collapse navbar-collapse"
            style={{ flexGrow: 0 }}
            id="navbarSupportedContent"
          >
            <ul className="list navbar-nav" dir="rtl">
              <NavLink
                to="/Dashboard"
                className="link-nav"
                activeclassname="active"
              >
                المنشورات
              </NavLink>
              <NavLink
                to="/Emails"
                className="link-nav"
                activeclassname="active"
              >
                الرسائل
              </NavLink>
              <NavLink to="/cv" className="link-nav" activeclassname="active">
                السيرة الذاتية
              </NavLink>
              <NavLink
                to="/images"
                className="link-nav"
                activeclassname="active"
              >
                الصور
              </NavLink>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
