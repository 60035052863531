import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Dashbord from "./Pages/Dashbord/Dashbord";
import EmailDashbord from "./Pages/Dashbord/emailDashbord";
import RequireAuth from "./Pages/Login/RequirdAuth";
import NotFound from "./Pages/NotFound";
import Cv from "./Pages/Cv/Cv";
import Images from "./Pages/Images/Imges";
import Details from "./Pages/Details/Details";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/Details/:id" element={<Details />} />
        <Route element={<RequireAuth />}>
          <Route path="/Dashboard" element={<Dashbord />} />
          <Route path="/Emails" element={<EmailDashbord />} />
          <Route path="/cv" element={<Cv />} />
          <Route path="/images" element={<Images />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

function MainApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default MainApp;
