import { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login() {
  const [inputForm, setinputForm] = useState({
    username: "",
    password: "",
  });
  const [accept, setaccept] = useState(false);
  const nav = useNavigate();

  async function Handsubmit(e) {
    e.preventDefault();
    try {
      let res = await axios.post(
        "https://muthanna-alazawi.iq/api/Account/login",
        {
          username: inputForm.username,
          password: inputForm.password,
        }
      );

      const token = res.data.token;
      localStorage.setItem("token", token);
      nav("/Dashboard");
    } catch (err) {
      console.log(err);
      setaccept(true);
    }
  }

  return (
    <div className="bg-login">
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <div className="row w-75">
          <div
            className="card flex-row border-0 shadow rounded-3 overflow-hidden"
            style={{ paddingLeft: "0px " }}
          >
            <div className="card-img-left d-none d-md-flex"></div>
            <div className="card-body" dir="rtl">
              <h5 className="card-title text-center mb-5 fw-bold fs-5 title-login">
                تسجيل الدخول
              </h5>
              <form onSubmit={Handsubmit}>
                {accept && (
                  <div className="text-danger mb-3">
                    خطا في كلمة السر او البريد الاكتروني
                  </div>
                )}

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control input-login"
                    placeholder=""
                    required
                    value={inputForm.username}
                    onChange={(e) => {
                      setinputForm({ ...inputForm, username: e.target.value });
                    }}
                  />
                  <label>البريد الاكتروني</label>
                </div>
                <hr />
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control input-login"
                    placeholder="Password"
                    required
                    value={inputForm.password}
                    onChange={(e) => {
                      setinputForm({ ...inputForm, password: e.target.value });
                    }}
                  />

                  <label>كلمة السر</label>
                </div>

                <div className="d-grid mb-2">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary btn-login text-uppercase button-login"
                  >
                    تسجيل الدخول
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
