import { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../Comonent/Navbar/Navbar";
export default function Images(prop) {
  const [Data, setData] = useState([]);
  const [rerunuseeffect, setrerunuseeffect] = useState(0);
  const [displayModel, setdisplayModel] = useState(false);
  const [formData, setFormData] = useState({
    mainImageUrl: null,
    secondaryImageUrl: null,
  });
  const [ErrorHendel, setErrorHendel] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get("https://muthanna-alazawi.iq/api/Hero", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setErrorHendel(false);
      });
  }, [rerunuseeffect]);

  async function handEdite(id) {
    setdisplayModel(true);
    try {
      const res = await axios.get(
        `https://muthanna-alazawi.iq/api/Hero/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setFormData({
        ...res.data,
        mainImageUrl: res.data.mainImageUrl || null,
        secondaryImageUrl: res.data.secondaryImageUrl || null,
        id: id,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const BASE_URL = "https://muthanna-alazawi.iq/";
  const showuser = Data.map((user, index) => (
    <tr key={index}>
      <td>
        <img src={`${BASE_URL}/${user.mainImageUrl}`} alt="" width="100px" />
      </td>
      <td>
        <img
          src={`${BASE_URL}/${user.secondaryImageUrl}`}
          alt=""
          width="100px"
        />
      </td>
      <td>
        <img
          src="/Images/Edite.png"
          alt="Edit"
          style={{ width: "25px" }}
          className="Edit-button"
          onClick={() => handEdite(user.id)}
        />
      </td>
    </tr>
  ));

  async function handSubmit(e) {
    e.preventDefault();
    if (isSubmitting || !formData.id) return;
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    let headersConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };

    formDataToSend.append("MainImage", formData.mainImageUrl);
    formDataToSend.append("SecondaryImage", formData.secondaryImageUrl);

    try {
      const url = `https://muthanna-alazawi.iq/api/Hero/update-image/${formData.id}`;
      await axios.put(url, formDataToSend, headersConfig);

      setrerunuseeffect((prev) => prev + 1);
      setdisplayModel(false);
      setFormData({});
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Navbar show="Dasjbord" />
      <div className="size">
        {displayModel && (
          <div className="Model">
            <div className="Content-Model">
              <div className="button-content">
                <img
                  src="/Images/close.svg"
                  alt="Close"
                  className="clouse-button"
                  onClick={() => setdisplayModel(false)}
                />
              </div>
              <form className="form-content" onSubmit={handSubmit}>
                <label className="lable-form">صورة الرئيسية</label>
                <input
                  type="file"
                  className="input-form"
                  accept="image/*"
                  required
                  onChange={(e) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      mainImageUrl: e.target.files[0],
                    }));
                  }}
                />
                <label className="lable-form">صورة الرئيسية الذاتية </label>
                <input
                  type="file"
                  className="input-form"
                  accept="image/*"
                  required
                  onChange={(e) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      secondaryImageUrl: e.target.files[0],
                    }));
                  }}
                />

                <p className="text-danger fs-5" hidden={ErrorHendel}>
                  لم يتم حفظ البيانات الرجاء المحاولة مرة اخرى لاحقا
                </p>

                <button
                  className="button-event"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "... جاري حفظ " : "حفظ"}
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="container" dir="rtl">
          <h3 className="title-section"> الصور </h3>
          <div className="parent-Table">
            <table className="table">
              <thead>
                <tr>
                  <th> الصورة الرئيسية </th>
                  <th> صورة السيرة الذاتية </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="text-center align-middle desctop-table">
                {showuser}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
