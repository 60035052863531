import { useEffect, useState } from "react";
import "./Details.css";
import axios from "axios";
import { Link, useNavigate, useNavigation } from "react-router-dom";
export default function Details() {
  const [Information, setInformation] = useState({
    title: "",
    description: "",
    imageORvideo: "",
  });
  const [News, setNews] = useState([]);
  const id = window.location.pathname.split("/").slice(-1)[0];
  const isVideo = (url) => /\.(mp4|webm|ogg|avi|mov)$/i.test(url);
  const BASE_URL = "https://muthanna-alazawi.iq/";
  useEffect(() => {
    axios
      .get("https://muthanna-alazawi.iq/api/News")
      .then((data) => setNews(data.data));
  }, []);

  useEffect(() => {
    const filterInformation = News.filter((data) => data.id == id);
    if (filterInformation.length > 0) {
      setInformation({
        title: filterInformation[0].title,
        description: filterInformation[0].description,
        imageORvideo: filterInformation[0].imageUrl,
      });
    }
  }, [News, id]);
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    const newPath = `/Details/${id}`;
    navigate(newPath, { replace: true });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="d-flex parent-content-details flex-wrap">
      <div
        className="d-flex justify-content-center flex-column container-div "
        dir="rtl"
      >
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb text-dark">
              <li className="breadcrumb-item">
                <Link to={"/"}>الصفحة الرئيسية </Link>
              </li>
              &nbsp; / &nbsp;
              <li className="breadcrumb-item active" aria-current="page">
                {Information.title}
              </li>
            </ol>
          </nav>
        </div>
        <div className="card-details col-12 d-flex flex-column">
          <div className="w-100">
            {isVideo(Information.imageORvideo) ? (
              <video controls style={{ width: "100%" }}>
                <source src={`${BASE_URL}/${Information.imageORvideo}`} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={`${BASE_URL}${Information.imageORvideo}`}
                alt=""
                className="w-100"
              />
            )}
          </div>
          <div className=" d-flex align-items-center flex-column conternt-text-details">
            <h3 className="title-details">{Information.title}</h3>
            <p>{Information.description}</p>
          </div>
        </div>
      </div>

      <div className="card-read-more">
        <div className="title-card-read-more">الأكثر قراءة</div>
        <div className="news-section-read-more">
          {News.filter((data) => data.id !== id)
            .slice(-4)
            .reverse()
            .map((data) => (
              <div
                className="news-card-read-more"
                key={data.id}
                onClick={() => handleNavigate(data.id)}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="col-4">
                  <img src={`${BASE_URL}${data.imageUrl}`} alt={data.title} />
                </div>
                <div className="col-8 pe-2" dir="rtl">
                  <h4>
                    <span>{data.title}</span>
                  </h4>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
