import axios from "axios";
import "./Dashbord.css";
import { useEffect, useState } from "react";
import Navbar from "../../Comonent/Navbar/Navbar";

export default function Dashbord() {
  const [Data, setData] = useState([]);
  const [rerunuseeffect, setrerunuseeffect] = useState(0);
  const [displayModel, setdisplayModel] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get("https://muthanna-alazawi.iq/api/News")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [rerunuseeffect]);

  async function handDelete(id) {
    try {
      const res = await axios.delete(
        `https://muthanna-alazawi.iq/api/News/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setrerunuseeffect((prv) => prv + 1);
        setdisplayModel(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handEdite(id) {
    setdisplayModel(true);
    axios
      .get(`https://muthanna-alazawi.iq/api/News/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => setFormData(res.data));
  }

  const BASE_URL = "https://muthanna-alazawi.iq/";
  const isVideo = (url) => /\.(mp4|webm|ogg|avi|mov)$/i.test(url);
  const show = Data.map((data) => (
    <div
      key={data.id}
      className="card-content d-flex g-3 justify-content-between align-items-center"
      style={{ padding: "0" }}
      dir="ltr"
    >
      {isVideo(data.imageUrl) ? (
        <video controls style={{ width: "100%" }}>
          <source src={`${BASE_URL}/${data.imageUrl}`} />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          src={`${BASE_URL}/${data.imageUrl}`}
          alt=""
          style={{ width: "70%", height: "300px" }}
        />
      )}
      <div className="word-news overflow-hidden">
        <h3>{data.title}</h3>
        <p dir="rtl">{data.description}</p>
      </div>
      <div className="d-flex actions-icon-descktop">
        <img
          src="/Images/Edite.png"
          alt="Edit"
          className="Edit-button"
          onClick={() => handEdite(data.id)}
        />
        <img
          className=" Delete-button"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          src="/Images/Delete.png"
          alt="Delete"
        />

        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  تأكيد الحذف
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  style={{ margin: "0" }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">هل حقا تريد حذف هذا المنشور </div>
              <div
                className="modal-footer"
                dir="ltr"
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  الغاء
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => handDelete(data.id)}
                >
                  حذف
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  const showmobile = Data.map((data) => (
    <div
      key={data.id}
      className="card-content d-flex flex-column g-3 justify-content-center align-items-center"
      style={{ padding: "0" }}
      dir="ltr"
    >
      {isVideo(data.imageUrl) ? (
        <video controls className="video-style">
          <source src={`${BASE_URL}/${data.imageUrl}`} />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          src={`${BASE_URL}/${data.imageUrl}`}
          alt=""
          style={{ width: "100%" }}
        />
      )}
      <div className="word-news">
        <h3>{data.title}</h3>
        <p dir="rtl">{data.description}</p>
      </div>
      <div className="d-flex actions-icon-mobile">
        <img
          src="/Images/Edite.png"
          alt="Edit"
          className="Edit-button"
          onClick={() => handEdite(data.id)}
        />

        <img
          className="Delete-button"
          data-bs-toggle="modal"
          data-bs-target={`#staticBackdrop-${data.id}`}
          src="/Images/Delete.png"
          alt="Delete"
        />

        <div
          className="modal fade"
          id={`staticBackdrop-${data.id}`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby={`staticBackdropLabel-${data.id}`}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id={`staticBackdropLabel-${data.id}`}
                >
                  تأكيد الحذف
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ margin: "0" }}
                ></button>
              </div>
              <div className="modal-body">هل حقا تريد حذف هذا المنشور</div>
              <div
                className="modal-footer"
                dir="ltr"
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  الغاء
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => handDelete(data.id)}
                >
                  حذف
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  async function handSubmit(e) {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    const formDataToSend = new FormData();
    let headersConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };

    formDataToSend.append("Title", formData.title);
    formDataToSend.append("Description", formData.description);
    formDataToSend.append("Image", formData.image);

    try {
      const url = formData.id
        ? `https://muthanna-alazawi.iq/api/News/update-post/${formData.id}`
        : "https://muthanna-alazawi.iq/api/News/add-post";

      const method = formData.id ? axios.put : axios.post;
      await method(url, formDataToSend, headersConfig);
      setrerunuseeffect((prev) => prev + 1);
      setdisplayModel(false);
      setFormData({});
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Navbar show="Dasjbord" />

      <div className="size">
        {displayModel && (
          <div className="Model">
            <div className="Content-Model">
              <div className="button-content">
                <img
                  src="/Images/Close.svg"
                  alt="Close"
                  className="clouse-button"
                  onClick={() => setdisplayModel(false)}
                />
              </div>
              <form className="form-content" onSubmit={handSubmit}>
                <label className="lable-form">عنوان المنشور </label>
                <input
                  type="text"
                  className="input-form"
                  name="title"
                  value={formData.title || ""}
                  required
                  onChange={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                  }}
                  autoComplete="off"
                />
                <label className="lable-form">وصف المنشور </label>
                <textarea
                  className="input-form "
                  autoComplete="off"
                  required
                  value={formData.description || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, description: e.target.value });
                  }}
                ></textarea>
                <label className="lable-form">صورة المنشور </label>
                <input
                  type="file"
                  className="input-form"
                  name="Image"
                  accept="image/*,video/*"
                  required
                  onChange={(e) => {
                    setFormData({ ...formData, image: e.target.files.item(0) });
                  }}
                />
                <button
                  className="button-event"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "... جاري حفظ " : "حفظ"}
                </button>
              </form>
            </div>
          </div>
        )}

        <div className="container" dir="rtl" style={{ marginBottom: "130px" }}>
          <h3 className="title-section">المنشورات </h3>
          <img
            src="/Images/insert.png"
            alt="add"
            className="insert.png"
            onClick={() => {
              setFormData({});
              setdisplayModel(true);
            }}
          />

          <div className="parent-Table">
            <div className="row d-flex flex-column justify-content-center align-items-center descktop-table">
              {show}
            </div>

            <div className="row d-flex flex-column justify-content-center align-items-center mobile-table">
              {showmobile}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
