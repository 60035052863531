import React from "react";

function NotFound() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column my-5 text-white">
      <h1 className="text-center text-dark mb-3">Page Not found - 404</h1>
    </div>
  );
}

export default NotFound;
