import { Fragment, useEffect, useState } from "react";
import "./Home.css";
import axios from "axios";
import Navbar from "../../Comonent/Navbar/Navbar";
import { Link } from "react-router-dom";

export default function Home() {
  const [inputForm, setinputForm] = useState({
    email: "",
    massage: "",
  });

  const [Images, setImages] = useState({});
  const [Cv, setCv] = useState([]);
  const BASE_URL = "https://muthanna-alazawi.iq/";
  const [accept, setaccept] = useState(false);
  const [News, setNews] = useState([]);

  const isVideo = (url) => /\.(mp4|webm|ogg|avi|mov)$/i.test(url);
  const [activeIndex, setActiveIndex] = useState(0);
  const delay = 6000; // Change image every 3 seconds

  const handleChange = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % News.slice(-4).length);
    }, delay);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [News]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app3.weatherwidget.org/js/?id=ww_55e0cd3078fa8";
    script.async = true;
    document.body.appendChild(script);

    axios.get("https://muthanna-alazawi.iq/api/Hero").then((data) => {
      setImages(data.data[0]);
    });

    axios
      .get("https://muthanna-alazawi.iq/api/News")
      .then((data) => setNews(data.data));

    axios
      .get("https://muthanna-alazawi.iq/api/CV")
      .then((data) => setCv(data.data));
  }, []);

  async function Handsubmit(e) {
    setaccept(true);
    e.preventDefault();
    try {
      await axios.post("https://muthanna-alazawi.iq/api/Messages/add-message", {
        email: inputForm.email,
        msgContent: inputForm.massage,
      });
      setTimeout(() => {
        setaccept(false);
      }, 5000);
      setinputForm({ email: "", massage: "" });
    } catch (err) {
      console.log(err);
    }
  }

  const [expandedNewsId, setExpandedNewsId] = useState(null);

  return (
    <div>
      <Navbar show="Home" />

      <div
        className="row row-mobile"
        style={{ marginRight: "0px", marginBottom: "121px" }}
        id="Home"
      >
        <div className="Edite-cart">
          <img
            src={`${BASE_URL}${
              Images.mainImageUrl ? Images.mainImageUrl.replace(/^\//, "") : ""
            }`}
            alt=""
            className="image-show-descktop"
          />
          <div className="title-head">
            <div className="content-discrption">
              المهندس مثنى ثائر العزاوي
              <br />
              <br />
              انطلاقة شبابية واعدة، وإشراقة جيلٍ طموح
              <div className="buttons-contener d-flex">
                <a className="call-me-button" href="#call-us">
                  تواصل معنا
                </a>

                <a className="info-button" href="#cv">
                  معلوماتي
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cv-section" dir="rtl" id="cv">
        <div className="image-cv">
          <img
            src={`${BASE_URL}/${Images.secondaryImageUrl}`}
            alt=""
            width="595px"
            height="523px"
          />
        </div>
        <div className="content-cv-section">
          <h2 className="title-cv-section">السيرة الذاتية</h2>
          <p className="descrption-cv-section">
            ستة عشر عاما من العمل في المجال الإداري وتكنولوجيا المعلومات، سنوات
            من الاجتهاد والمثابرة في الوصول، تقلّد فيها مناصب إدارية عديدة ضمن
            تخصصّهِ وأبرز المناصب التي تولّاها:
          </p>
          <ul className="list-cv-section">
            {Cv.map((data, index) => {
              return (
                <li key={index}>
                  <div className="icons">
                    <img src="Images/task_alt.svg" alt="" />
                  </div>
                  <div>{data.content}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="custom-carousel-container">
        <ul className="custom-carousel my-carousel custom-carousel--thumb">
          {News.slice(-4).map((slide, index) => (
            <Fragment key={slide.id}>
              <input
                className="custom-carousel__activator"
                type="radio"
                id={slide.id}
                name="thumb"
                checked={activeIndex === index}
                onChange={() => handleChange(index)}
              />
              <li
                className={`custom-carousel__slide ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <img
                  src={`${BASE_URL}${slide.imageUrl}`}
                  alt=""
                  className="w-100 h-100 "
                />
              </li>
              <div className="custom-carousel__controls">
                <label
                  className="custom-carousel__control custom-carousel__control--backward"
                  htmlFor={
                    index === 0 ? News[News.length - 1].id : News[index - 1].id
                  }
                  onClick={() =>
                    handleChange((index - 1 + News.length) % News.length)
                  }
                ></label>
                <label
                  className="custom-carousel__control custom-carousel__control--forward"
                  htmlFor={
                    index === News.length - 1 ? News[0].id : News[index + 1].id
                  }
                  onClick={() => handleChange((index + 1) % News.length)}
                ></label>
              </div>
            </Fragment>
          ))}
          <div className="custom-carousel__indicators">
            {News.slice(-4).map((slide, index) => (
              <label
                key={slide.id}
                className="custom-carousel__indicator"
                htmlFor={slide.id}
                style={{
                  backgroundImage: `url(${BASE_URL}${
                    slide.imageUrl ? slide.imageUrl.replace(/^\//, "") : ""
                  })`,
                }}
                onClick={() => handleChange(index)}
              ></label>
            ))}
          </div>
        </ul>
      </div>

      <div className="newspaper-section" dir="rtl" id="news">
        <h3 className="title-newspaper-section">اخبار</h3>
        <h1 className="sub-title-newspaper-section">
          تابع اخبار المهندس مثنى العزاوي
        </h1>
        <div className="list-newspaper-section w-100">
          <div
            id="carouselExampleControlsNoTouching"
            className="carousel slide news-mobile"
            data-bs-touch="false"
            data-bs-interval="false"
          >
            <div className="carousel-inner w-100">
              {News.map((data, index) => {
                const isExpanded = expandedNewsId === data.id;
                return (
                  <div
                    key={data.id}
                    className={`carousel-item ${
                      index === 0 ? "active" : ""
                    } w-100`}
                  >
                    <div
                      key={data.id}
                      className="col-4 card-newspaper-section"
                      style={{ height: isExpanded ? "auto" : "552px" }}
                    >
                      {isVideo(data.imageUrl) ? (
                        <video controls style={{ width: "100%" }}>
                          <source src={`${BASE_URL}/${data.imageUrl}`} />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img src={`${BASE_URL}${data.imageUrl}`} alt="" />
                      )}
                      <h3 className="tilte-news-inercard">{data.title}</h3>
                      <p className="descrption-news-inercard">
                        {data.description}
                      </p>

                      <Link
                        to={`Details/${data.id}`}
                        className="read-more-buttons"
                        style={{ marginBottom: "20px" }}
                      >
                        اقرأ المزيد
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          <div className="news-descktop">
            {News.map((data) => {
              const isExpanded = expandedNewsId === data.id;
              return (
                <div
                  key={data.id}
                  className="col-4 card-newspaper-section"
                  style={{ height: isExpanded ? "auto" : "552px" }}
                >
                  {isVideo(data.imageUrl) ? (
                    <video controls style={{ width: "100%" }}>
                      <source src={`${BASE_URL}/${data.imageUrl}`} />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img src={`${BASE_URL}${data.imageUrl}`} alt="" />
                  )}
                  <h3 className="tilte-news-inercard">{data.title}</h3>
                  <p
                    className="descrption-news-inercard"
                    style={{
                      WebkitBoxOrient: isExpanded ? "horizontal" : "vertical",
                    }}
                  >
                    {data.description}
                  </p>
                  <Link to={`Details/${data.id}`} className="read-more-buttons">
                    اقرأ المزيد
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center weather-section"
        style={{ fontSize: "40px" }}
      >
        <div
          id="ww_55e0cd3078fa8"
          v="1.3"
          loc="auto"
          a='{"t":"responsive","lang":"en","sl_lpl":1,"ids":[],"font":"Times","sl_ics":"one_a","sl_sot":"celsius","cl_bkg":"rgb(255 255 255 / 4%)","cl_font":"#000","cl_cloud":"#FFFFFF","cl_persp":"#81D4FA","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_tof":"7"}'
        >
          Weather Data Source:{" "}
          <a
            href="https://wetterlang.de/wetter_21_tage/"
            id="ww_55e0cd3078fa8_u"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wettervorhersage 21 tage
          </a>
        </div>
      </div>

      <div className="call-us-section" dir="rtl" id="call-us">
        <div className="descrotion-call-us-section col-7">
          <h3 className="title-call-us-section">تواصل معنا</h3>
          <h1 className="sub-title-call-us-section">سعداء بتواصلكم</h1>
          <p className="explan-call-us-section">
            تواصلوا معنا للاستفسار او تقديم شكاوى او مقترحات، سنكون سعداء
            بمساعدتكم وخدمتكم في اي وقت.
          </p>
          <div className="mail-section">
            <p className="title-mail-section">
              <img src="Images/mail.svg" alt="" /> &nbsp; البريد الالكتروني
            </p>
            <p className="perosnal-mail-section">info@muthanna-alazawi.iq</p>
          </div>
        </div>
        <div className="form-call-us col-5">
          <form onSubmit={Handsubmit}>
            <label>*بريدك الالكتروني</label>
            <input
              type="email"
              placeholder="بريدك الإلكتروني..."
              required
              value={inputForm.email}
              onChange={(e) => {
                setinputForm({ ...inputForm, email: e.target.value });
              }}
            />
            <label>*الرسالة</label>
            <textarea
              placeholder="اكتب هنا..."
              required
              value={inputForm.massage}
              onChange={(e) => {
                setinputForm({ ...inputForm, massage: e.target.value });
              }}
            ></textarea>
            <div className=" d-flex justify-content-end">
              <button className="info-button info-button-submit" type="submit">
                ارسال
              </button>
            </div>
            {accept && (
              <p className="text-success fs-4">تم ارسال الرسالة بنجاح </p>
            )}
          </form>
        </div>
      </div>

      <div className="footer-section" dir="rtl">
        <img src="Images/copyright.svg" alt="" />
        <span>جميع الحقوق محفوظة ٢٠٢٤ - المهندس مثنى العزاوي</span>
      </div>
    </div>
  );
}
