import { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../Comonent/Navbar/Navbar";
export default function Cv(prop) {
  const [Data, setData] = useState([]);
  const [rerunuseeffect, setrerunuseeffect] = useState(0);
  const [displayModel, setdisplayModel] = useState(false);
  const [formData, setFormData] = useState({
    content: "",
  });
  const [ErrorHendel, setErrorHendel] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get("https://muthanna-alazawi.iq/api/CV", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [rerunuseeffect]);

  async function handDelete(id) {
    try {
      const res = await axios.delete(
        `https://muthanna-alazawi.iq/api/CV/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setrerunuseeffect((prv) => prv + 1);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handEdite(id) {
    setdisplayModel(true);
    axios
      .get(`https://muthanna-alazawi.iq/api/CV/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => setFormData(res.data));
  }

  const showuser = Data.map((user, index) => (
    <>
      <tr key={index}>
        <td>{user.content}</td>
        <td>
          <img
            src="/Images/Edite.png"
            alt="Delete"
            className="Delete-button"
            style={{ width: "30px" }}
            onClick={() => handEdite(user.id)}
          />
          <img
            src="/Images/Delete.png"
            alt="Delete"
            className="Delete-button"
            style={{ width: "30px" }}
            data-bs-toggle="modal"
            data-bs-target={`#staticBackdrop-${user.id}`}
          />
        </td>
      </tr>

      <div
        className="modal fade"
        id={`staticBackdrop-${user.id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby={`staticBackdropLabel-${user.id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`staticBackdropLabel-${user.id}`}>
                تأكيد الحذف
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ margin: "0" }}
              ></button>
            </div>
            <div className="modal-body">هل حقا تريد حذف هذا المنجز </div>
            <div
              className="modal-footer"
              dir="ltr"
              style={{
                flexDirection: "row-reverse",
                justifyContent: "flex-start",
              }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                الغاء
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => handDelete(user.id)}
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ));

  async function handSubmit(e) {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    let headersConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    console.log(formData.id);
    try {
      const url = formData.id
        ? `https://muthanna-alazawi.iq/api/CV/update-CvContent/${formData.id}`
        : "https://muthanna-alazawi.iq/api/CV/add-CvContent";

      const method = formData.id ? axios.put : axios.post;
      await method(url, formData, headersConfig);
      setrerunuseeffect((prev) => prev + 1);
      setdisplayModel(false);
      setFormData({});
    } catch (error) {
      console.log(error);
      setErrorHendel(false);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Navbar show="Dasjbord" />
      <div className="size">
        {displayModel && (
          <div className="Model">
            <div className="Content-Model">
              <div className="button-content">
                <img
                  src="/Images/close.svg"
                  alt="Close"
                  className="clouse-button"
                  onClick={() => setdisplayModel(false)}
                />
              </div>
              <form className="form-content" onSubmit={handSubmit}>
                <label className="lable-form">المنصب</label>
                <textarea
                  className="input-form "
                  autoComplete="off"
                  required
                  value={formData.content || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, content: e.target.value });
                  }}
                ></textarea>
                <p className="text-danger fs-5" hidden={ErrorHendel}>
                  لم يتم حفظ البيانات الرجاء المحاولة مرة اخرى لاحقا
                </p>

                <button
                  className="button-event"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "... جاري حفظ " : "حفظ"}
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="container" dir="rtl">
          <h3 className="title-section">السيرة الذاتية </h3>
          <img
            src="/Images/insert.png"
            alt=""
            className="insert.png"
            onClick={() => {
              setFormData({});
              setdisplayModel(true);
            }}
          />
          <div className="parent-Table">
            <table className="table">
              <thead>
                <tr>
                  <th> المناصب </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="text-center align-middle desctop-table">
                {showuser}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
